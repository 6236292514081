import store from "@/store";

import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersEdit() {
  const { router } = useRouter();
  const toast = useToast();

  const updateUser = (editUser) => {
    store
      .dispatch("app-user/editUser", { editUser })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        // redirect to list page
        router.push({ name: "user-list" });
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error updating User",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  const fetchUserByID = (user) => {
    const userID = user.value.ID;
    store
      .dispatch("app-user/fetchUserByID", { userID })
      .then((response) => {
        user.value = response.data;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching user detail",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch("app-user/fetchCustomers", {})
      .then((response) => {
        const customers = response.data.companies;

        callback(customers);
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching customers list: ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    updateUser,
    fetchUserByID,
    fetchCustomers,
  };
}
