<template>
  <b-row class="mt-1">
    <b-col cols="12">
      <user-edit-form-wizard :edit-user="editUser" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import UserEditFormWizard from "./UserEditFormWizard.vue";
import useUsersEdit from "./useUsersEdit";
import userStoreModule from "../userStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    UserEditFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const editUser = ref({});

    const USER_APP_STORE_MODULE_NAME = "app-user";

    const { fetchUserByID } = useUsersEdit();

    // Pull userID from router
    const { route } = useRouter();
    const userID = route.value.params.id;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    editUser.value.ID = userID;
    fetchUserByID(editUser);

    return {
      editUser,
    };
  },
};
</script>
