









































































































































































































































































































import Vue from "vue";

// Typescript Files
import * as Api from "@/axios-generated/backoffice/index";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BMedia,
  BTable,
  BFormRadioGroup,
  BAvatar,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { resolveAvatarLink } from "@core/utils/utils";
import useUsersEdit from "./useUsersEdit";

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BMedia,
    BTable,
    BFormRadioGroup,
    BAvatar,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      required,
      email,
      publicPath: process.env.BASE_URL,
      selectedCompany: null,
      fields: ["entreprise", "code"],
      genderOptions: [
        { value: Api.UserGenderEnum.Male, text: "Homme" },
        { value: Api.UserGenderEnum.Female, text: "Femme" },
        { value: Api.UserGenderEnum.Other, text: "Autre" },
      ],
      jobRoleOptions: [
        { value: "select_value", text: "Select Value" },
        { value: Api.UserJobRoleEnum.Technical, text: "Technique" },
        { value: Api.UserJobRoleEnum.Business, text: "Business" },
        { value: Api.UserJobRoleEnum.Marketing, text: "Marketing" },
      ],
      roleOptions: [
        { value: "select_value", text: "Select Value" },
        { value: Api.UserRoleEnum.AdminBo, text: Api.UserRoleEnum.AdminBo },
        {
          value: Api.UserRoleEnum.BusinessBo,
          text: Api.UserRoleEnum.BusinessBo,
        },
        {
          value: Api.UserRoleEnum.AdminCustomer,
          text: Api.UserRoleEnum.AdminCustomer,
        },
        {
          value: Api.UserRoleEnum.BusinessCustomer,
          text: Api.UserRoleEnum.BusinessCustomer,
        },
      ],
      statusOptions: [
        { value: "select_value", text: "Select Value" },
        { value: Api.UserStatusEnum.Enabled, text: Api.UserStatusEnum.Enabled },
        {
          value: Api.UserStatusEnum.Disabled,
          text: Api.UserStatusEnum.Disabled,
        },
      ],
    };
  },
  props: {
    editUser: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { updateUser, fetchCustomers } = useUsersEdit();

    return {
      updateUser,
      fetchCustomers,
      resolveAvatarLink,
    };
  },
  methods: {
    initSelectedCompany() {
      const indexSelected = this.$refs.selectableTable.sortedItems.findIndex(
        (item) => item.ID === this.editUser.company_ID
      );
      if (indexSelected !== -1) {
        this.$refs.selectableTable.selectRow(indexSelected);
      } else {
        this.$refs.selectableTable.selectRow(0);
      }
    },
    onRowSelected(items) {
      this.editUser.company_ID = items[0].ID;
      this.editUser.company = items[0];
    },
    submitForm(editUser) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous confirmer l'opération ?", {
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.updateUser(editUser);
          }
        });
    },
    validationForm() {
      this.initSelectedCompany();
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
});
